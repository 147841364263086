// Set jQuery
window.$ = window.jQuery;

// Set Grid.js
import { Grid, html } from "gridjs";

/*----------------------------------
* Sliders
*----------------------------------*/

var swiper = new Swiper('.swiper-container', {
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  }
});

/*----------------------------------
* Gutenberg
*----------------------------------*/



/*----------------------------------
* Top Menu
*----------------------------------*/

$(document).ready(function () {

  $('.dropdown-menu a').click(function (e) {
    e.preventDefault();
    window.open(event.target.href, '_self');
  });

  $('.item-accordion > a').click(function (e) {
    e.preventDefault();
    e.target.classList.toggle("active");
    if (document.getElementById($(e.target).attr('id').replace('title', 'text'))) document.getElementById($(e.target).attr('id').replace('title', 'text')).style.display = e.target.classList.contains("active") ? 'block' : 'none';
  });

});

/*----------------------------------
* Actualidad (Lazy Load)
*----------------------------------*/

$(document).ready(function ($) {
  fakeLazyLoad('.page-actualidad .ph-post-list-1__item', 7, 4, '#show-more');
});

/*----------------------------------
* (Fake) Lazy Load
*----------------------------------*/

function fakeLazyLoad(postsSelector, initialPostsNumber, postsPerLoad, loadMoreButtonSelector) {

  $(function () {
    $(postsSelector).hide();

    $(postsSelector).slice(0, initialPostsNumber).show();

    $(loadMoreButtonSelector).on('click', function (e) {
      e.preventDefault();
      $(postsSelector + ':hidden').slice(0, postsPerLoad).slideDown();
      if ($(postsSelector + ':hidden').length === 0) {
        $(loadMoreButtonSelector).fadeOut('slow');
      }
    });
  });

  $('a[href=#top]').click(function () {
    $('body,html').animate({
      scrollTop: 0
    }, 600);
    return false;
  });

  $(window).scroll(function () {
    if ($(this).scrollTop() > 50) {
      $('.totop a').fadeIn();
    } else {
      $('.totop a').fadeOut();
    }
  });

}

/*----------------------------------
* Tabs
*----------------------------------*/
$(document).ready(function () {

  // or show the tab on the url /?tab=donativos
  // var tabIdFromUrl = urlLast(window.location.href);
  // Show the first tab by default
  $('.tabs .tabs-stage .tab-stage').hide();
  $('.tabs .tabs-stage .tab-stage:first').show();
  $('.tabs .tabs-nav li:first').addClass('tab-active');

  // add click handler to change tab on click
  $('.tabs .tabs-nav a').on('click', function (event) {
    event.preventDefault();
    changeTab($(this).attr('href'));
  });

  $('.tabs .tabs-nav a').each(function () {
    if ($(this).attr('href') === location.hash) {
      $(this).click();
      window.scrollTo(0, 0);
    }
  });

  function changeTab(elem) {
    var tabNavId = elem + '-link';
    $('.tabs .tabs-nav li').removeClass('tab-active');
    $('.tabs .tabs-nav ' + tabNavId).parent().addClass('tab-active');
    $('.tabs .tabs-stage .tab-stage').hide();
    $(elem).show();
  }

  if (!localStorage.getItem("cookie-accept")) $("#cookies").show();
});

window.acceptCookies = function() {
  localStorage.setItem("cookie-accept", 1);
  $("#cookies").hide();
  return false;
}

/*----------------------------------
* Form
*----------------------------------*/

/*----------------------------------
* Helpers
*----------------------------------*/

function urlLast(url) {

  var array = url.split('/');
  return array[array.length - 1];

}
/*----------------------------------
* Mobile Menu
*----------------------------------*/
$('.mobile-menu-icon').click(function() {
  $('.mobile-menu').toggleClass('active');
});
/*----------------------------------
* Deployable subscription
*----------------------------------*/
$( "#suscripcion-newsletter" ).click(function() {
  $( "#form-subscription-newsletter" ).slideToggle( "slow" );
});

$( "#suscripcion" ).click(function() {
  $( "#form-subscription" ).slideToggle( "slow" );
});
/*----------------------------------
* Change nav subscription text
*----------------------------------*/
//$('#form-subscription input[type=submit]').val("Suscríbete");
$('#form-subscription input[type=checkbox]').parent().addClass("condiciones");
$('#form-subscription input[type=checkbox]').parent().addClass("checkbox-politicas");
/*----------------------------------
* Before last element in top-menu in orange
*----------------------------------*/
$('#top-menu>ul>li:nth-last-child(2)>a').addClass("destacar-colabora");
/*----------------------------------
* Last element in top-menu with icon
*----------------------------------*/
$('#top-menu>ul>li:last-child>a').after('<img src="'+window.location.origin+'/wp-content/themes/proyectohombre/assets/images/zona-privada/private-zone.png">');
$('#top-menu>ul>li:last-child>a').css({"color":"#006E7A"});
$('#top-menu>ul>li:last-child>img').css({"position":"absolute","top":"50%","transform":"translateY(-50%)","right":"-10px"});
/*----------------------------------
* Popup appears after some scroll on article
*----------------------------------*/
if($('#newsletter-banner').hasClass("popup-newsletter")){
  $('#newsletter-banner').hide();
}
var triggered = false;
window.onscroll = function() {
  var popupTriggerHeight = document.documentElement.scrollHeight/3.5;
  if($('#newsletter-banner').hasClass("popup-newsletter")){
    if((window.pageYOffset > popupTriggerHeight) && !$('#newsletter-banner').hasClass("closed-popup") && triggered == false){
      triggered = true;
      $('#newsletter-banner').slideToggle("slow", function() {
      });
    }
  }
  console.log(triggered);
}
/*----------------------------------
* Close Newsletter Popup
*----------------------------------*/
$('#banner-close').click( function(){
  $('#newsletter-banner').addClass('popup-closed');
  jQuery.ajax({
    type: "POST",
    url: "/wp-admin/admin-ajax.php",
    data: {
      "action":"close_banner",
    },
    success: function(response){
    },
    error: function(xhr, textStatus, errorThrown ) {
  }
  });
});
/*----------------------------------
* Swiper element in Private Zone
*----------------------------------*/
if($('.private-zone-swiper')){
   var zonaPrivadaSwiper = new Swiper('.private-zone-swiper',{
    allowTouchMove: false,
    autoHeight: true,
    height: 1225
   });
   if($('.private-zone-next-slide')){
    $('.private-zone-next-slide h4').click( function(){
      zonaPrivadaSwiper.slideNext();
    } );
   }
   if($('.private-zone-prev-slide')){
    $('.private-zone-prev-slide h4').click( function(){
      zonaPrivadaSwiper.slidePrev();
    } );
  }
}
/*----------------------------------
* User data modification controls
*----------------------------------*/
if($('#private-zone-modify-data')){
  var modificando = false;
  $('#private-zone-modify-data').click( function(){
    if(modificando == false){
      $('#private-zone-modify-data').html('Cancelar');
      modificando = true;
      $('#private-zone-save-data').css("opacity", "1");
      $('#private-zone-save-data').prop('disabled', false);
      $('.form-input').each(function(){
        $(this).prop( "disabled", false );
      });
    }
    else{
      modificando = false;
      $('#private-zone-modify-data').html('Modificar');
      $('#private-zone-save-data').css("opacity", "0.6");
      $('#private-zone-save-data').prop('disabled', true);
      $('.form-input').each(function(){
        $(this).prop( "disabled", true );
      });
    }
  } );
 }
/*----------------------------------
* User data modification AJAX calls
*----------------------------------*/
jQuery(document).ready(function ($) {
  $("#private-zone-save-data").on("click", function(){
      $('#private-zone-save-data').prop('disabled', true);
      $('#private-zone-save-data').css("opacity", "0.6");
      $('#private-zone-modify-data').html('Modificar');
      $('.form-input').each(function(){
        $(this).prop( "disabled", true );
      });
      console.log("Intentando AJAX...");
      var user = {
        name: $('#name').val(),
        surname: $('#surname').val(),
        email: $('#email').val(),
        telephone: $('#telephone').val(),
      }

      jQuery.ajax({
          type: "POST",
          url: "/wp-admin/admin-ajax.php",
          data: {
            "action":"modify_user_data",
            "user":user,
          },
          success: function(response){
            console.log(response);
            $('.modify-success').fadeIn(1000);
            $('.modify-success').delay(5000).fadeOut(1000);
            zonaPrivadaSwiper.updateAutoHeight(1000);
            setTimeout(function() { zonaPrivadaSwiper.updateAutoHeight(1000); }, 7020);
          },
          error: function(xhr, textStatus, errorThrown ) {
            $('.modify-fail').fadeIn(1000);
            $('.modify-fail').delay(5000).fadeOut(1000);
            zonaPrivadaSwiper.updateAutoHeight(1000);
            setTimeout(function() { zonaPrivadaSwiper.updateAutoHeight(1000); }, 7020);
            if (textStatus == 'timeout') {
              this.tryCount++;
              if (this.tryCount <= this.retryLimit) {
                //try again
                console.log("Reintentando conexión");
                $.ajax(this);
                return;
              }            
              return;
            }
            if (xhr.status == 500) {
              //handle error
              console.log("Orurrió un error a la hora de recibir la solicitud");
              console.log(errorThrown);
            } else {
              //handle error
              console.log("Ocurrió un error a la hora de enviar la solicitud");
              console.log(errorThrown);
            }
        }
      });
  });
/*----------------------------------
* User logout AJAX call
*----------------------------------*/
  $("#private-zone-logout").on("click", function(){
    console.log("Intentando AJAX...");

    jQuery.ajax({
        type: "POST",
        url: "/wp-admin/admin-ajax.php",
        data: {
          "action":"logout_user",
        },
        success: function(response){
          console.log(response);
          window.location.reload();
        },
        error: function(xhr, textStatus, errorThrown ) {
          if (textStatus == 'timeout') {
            this.tryCount++;
            if (this.tryCount <= this.retryLimit) {
              //try again
              console.log("Reintentando conexión");
              $.ajax(this);
              return;
            }            
            return;
          }
          if (xhr.status == 500) {
            //handle error
            console.log("Orurrió un error a la hora de recibir la solicitud");
            console.log(errorThrown);
          } else {
            //handle error
            console.log("Ocurrió un error a la hora de enviar la solicitud");
            console.log(errorThrown);
          }
      }
    });
  });
/*----------------------------------
* User subscribe to newsletter AJAX call
*----------------------------------*/
  $("#subscribe-newsletter").on("click", function(){

    var user = {
      name: $('#name').val(),
      surname: $('#surname').val(),
      email: $('#email').val(),
      privacyCheckbox: $('#privacy-policy-checkbox').prop('checked'),
    }

    console.log(user);

    for(var key in user){
      if((user[key] == "") || (key == "privacyCheckbox" && user[key] == false)){
        $("#"+key+"-error").fadeIn();
      }
      else{
        $("#"+key+"-error").fadeOut();
      }
    }

    if(user.name != "" && user.surname != "" && user.email != "" && user.privacyCheckbox == true){
      console.log("Intentando AJAX...");
      jQuery.ajax({
          type: "POST",
          url: "/wp-admin/admin-ajax.php",
          data: {
            "action":"subscribe_newsletter",
            "user":user,
          },
          success: function(response){
            console.log(response);
            $('.subscribe-success').fadeIn();
            $('.subscribe-success').delay(5000).fadeOut();
          },
          error: function(xhr, textStatus, errorThrown ) {
            $('.subscribe-fail').fadeIn();
            $('.subscribe-fail').delay(5000).fadeOut();
            if (textStatus == 'timeout') {
              this.tryCount++;
              if (this.tryCount <= this.retryLimit) {
                  //try again
                  console.log("Reintentando conexión");
                  $.ajax(this);
                  return;
              }            
              return;
            }
            if (xhr.status == 500) {
              //handle error
              console.log("Orurrió un error a la hora de recibir la solicitud");
              console.log(errorThrown);
            } else {
              //handle error
              console.log("Ocurrió un error a la hora de enviar la solicitud");
              console.log(errorThrown);
            }
        }
      });
    }
    else console.log("Datos incompletos");
  });
  /*----------------------------------
  * User password recovery AJAX call
  *----------------------------------*/
  $("#recover-password").on("click", function(){
    console.log("Intentando AJAX...");
    $userMail = $('#email').val();

    jQuery.ajax({
        type: "POST",
        url: "/wp-admin/admin-ajax.php",
        data: {
          "action":"recover_password",
          "email": $userMail
        },
        success: function(response){
          $("#recover-password").html("Reenviar");
          console.log(response);
          $('.recover-success').fadeIn();
          $('.recover-success').delay(5000).fadeOut();
        },
        error: function(xhr, textStatus, errorThrown ) {
          $('.recover-fail').fadeIn();
          $('.recover-fail').delay(5000).fadeOut();
          if (textStatus == 'timeout') {
            this.tryCount++;
            if (this.tryCount <= this.retryLimit) {
              //try again
              console.log("Reintentando conexión");
              $.ajax(this);
              return;
            }            
            return;
          }
          if (xhr.status == 500) {
            //handle error
            console.log("Ocurrió un error a la hora de recibir la solicitud");
            console.log(errorThrown);
          } else {
            //handle error
            console.log("Ocurrió un error a la hora de enviar la solicitud");
            console.log(errorThrown);
          }
      }
    });
  });
  /*----------------------------------
  * Recover documents in folder on icon click
  *----------------------------------*/
 
  const firstTab = $('#folder-explorer li:first-child');
  const secondTab = $('#folder-explorer li').eq(-2);
  const thirdTab = $('#folder-explorer li:last-child');

  var searchArray = [];

  secondTab.hide();
  thirdTab.hide();

  function changeTab(elem) {
    var tabNavId = elem + '-link';
    $('.tabs .tabs-nav li').removeClass('tab-active');
    $('.tabs .tabs-nav ' + tabNavId).parent().addClass('tab-active');
    $('.tabs .tabs-stage .tab-stage').hide();
    $(elem).show();
  }

  $(document).on("click", ".document-folder", function(){
    console.log("Intentando AJAX...");
    jQuery.ajax({
        type: "POST",
        url: "/wp-admin/admin-ajax.php",
        folderName: $(this).attr('name'),
        prevFolderName: $(this).attr('name'),
        folderId: $(this).attr('id'),
        folderImg: $(this).find('img').attr('src'),
        data: {
          "action":"get_folder_content",
          "guidDirectory": $(this).attr('id'),
        },
        beforeSend: function() {
          $("#document-table-wrapper-3").html("");
          changeTab(thirdTab.find('a').attr('href'));
        },
        success: function(response){
          //console.log(response);

          var defaultImage = "/wp-content/themes/proyectohombre/assets/images/zona-privada/carpeta_buscador.png";
          var secondaryImage = "/wp-content/themes/proyectohombre/assets/images/zona-privada/carpeta_breadcrumb.png";
          var tableHTML = generateTableHTML(JSON.parse(response)["GetDirectoryListResult"]["NukuaDocumento"]);

          searchArray.push({'id' : this.folderId, 'name' : this.folderName, 'inconImage': this.folderImg, 'content':tableHTML});

          breadcrumbGeneration(searchArray);
          //console.log(JSON.parse(response));
          // Organize tabs on addition
          if($.trim(thirdTab.text())){

            // TODO: update 3rd tab
            $("#document-table-wrapper-3").html(tableHTML);

            // Highlight current tab
            secondTab.find('span').text(thirdTab.find('span').text());
            thirdTab.find('span').text(this.folderName);
            firstTab.removeClass('tab-active');
            secondTab.removeClass('tab-active');
            thirdTab.addClass('tab-active');
            changeTab(thirdTab.find('a').attr('href'));
            thirdTab.find('img').attr('src', searchArray[searchArray.length-1]['inconImage']);
            if(searchArray[searchArray.length-2]['inconImage'] == defaultImage) secondTab.find('img').attr('src', secondaryImage);
            else secondTab.find('img').attr('src', searchArray[searchArray.length-2]['inconImage']);
          }
          else if($.trim(secondTab.text())){

            if(secondTab.find('span').text() != "Resultados de búsqueda"){
              // TODO: update 3rd tab
              $("#document-table-wrapper-3").html(tableHTML);

              // Highlight current tab
              firstTab.removeClass('tab-active');
              secondTab.removeClass('tab-active');
              thirdTab.addClass('tab-active');
              changeTab(thirdTab.find('a').attr('href'));
              thirdTab.show();
              thirdTab.find('span').text(this.folderName);
              thirdTab.find('img').attr('src', searchArray[searchArray.length-1]['inconImage']);
              if(searchArray[searchArray.length-2]['inconImage'] == defaultImage) secondTab.find('img').attr('src', secondaryImage);
              else secondTab.find('img').attr('src', searchArray[searchArray.length-2]['inconImage']);
            }
            else{
              $("#document-table-wrapper-2").html(tableHTML);

              // Highlight current tab
              firstTab.removeClass('tab-active');
              secondTab.addClass('tab-active');
              thirdTab.removeClass('tab-active');
              changeTab(secondTab.find('a').attr('href'));
              secondTab.show();
              secondTab.find('span').text(this.folderName);
              secondTab.find('img').attr('src', searchArray[searchArray.length-1]['inconImage']);
            }
          }
          else{
            
            // TODO: update 2nd tab
            $("#document-table-wrapper-2").html(tableHTML);

            // Highlight current tab
            firstTab.removeClass('tab-active');
            secondTab.addClass('tab-active');
            thirdTab.removeClass('tab-active');
            changeTab(secondTab.find('a').attr('href'));
            secondTab.show();
            secondTab.find('span').text(this.folderName);
            secondTab.find('img').attr('src', searchArray[searchArray.length-1]['inconImage']);
          }

        },
        error: function(xhr, textStatus, errorThrown ) {
          if (textStatus == 'timeout') {
            this.tryCount++;
            if (this.tryCount <= this.retryLimit) {
              //try again
              console.log("Reintentando conexión");
              $.ajax(this);
              return;
            }            
            return;
          }
          if (xhr.status == 500) {
            //handle error
            console.log("Ocurrió un error a la hora de recibir la solicitud");
            console.log(errorThrown);
          } else {
            //handle error
            console.log("Ocurrió un error a la hora de enviar la solicitud");
            console.log(errorThrown);
          }
      }
    });
  });
  
  /*----------------------------------
  * Generate breadcrumb
  *----------------------------------*/
  $(document).on("click", ".document-file", function(){
    console.log("Intentando AJAX...");
    var documentId = $(this).attr('id');
    var documentName = $(this).attr('name');
    window.open(
        "/wp-content/themes/proyectohombre/ph-download-file.php?name="+documentName+"&document="+documentId, "_blank");

  });
  /*----------------------------------
  * Generate breadcrumb
  *----------------------------------*/
  function breadcrumbGeneration(array){
    var breadcrumb = document.getElementById('document-breadcrumb');
    $('#document-breadcrumb').empty();

    if(array.length == 0){
      $("#prev-folder").hide();
    }
    else $("#prev-folder").show();

    for(var c = -1; c<array.length; c++){

      var span = document.createElement("span");
      var separator = document.createElement("span");
      separator.setAttribute("class", "breadcrumb-separator");
      separator.innerHTML = "/";

      if(c === array.length-1){
        span.setAttribute('class', "bread-current");
      }
      else{
        span.setAttribute('class', 'clickable-link');
        span.addEventListener('click', navigateBreadcrumb, false)
      }

      if(c == -1){
        span.insertAdjacentHTML('afterbegin', "Inicio");
        span.setAttribute('id', "inicio");
      }
      else{
        span.insertAdjacentHTML('afterbegin', array[c].name);
        span.setAttribute('id', array[c].id);
        span.prepend(separator);
      }
      breadcrumb.appendChild(span);
    }
  }
  function navigateBreadcrumb(){
    console.log(searchArray);
    if($(this).attr('id') == "inicio"){
      searchArray.length = 0;
    }
    else{
      for(var c=0; c<searchArray.length; c++){
        if($(this).attr('id') == searchArray[c].id){
          searchArray.length = c+2;
        }
      }
    }
    breadcrumbGeneration(searchArray);
    tabAdjustment(searchArray);
  }
  /*----------------------------------
  * Return to previous folder
  *----------------------------------*/
  $("#tab-1-link").on("click", function(){
    searchArray = [];
    tabAdjustment(searchArray);
  });
  $("#prev-folder, #tab-2-link").on("click", function(){
    console.log("Quitando tab...",searchArray);

    tabAdjustment(searchArray);
    //TODO: string of actions that depending on the previous folder's name gotten from Nukua, resets/rewrites or not tab content 

  });


  function tabAdjustment(searchArray){
    var defaultImage = "/wp-content/themes/proyectohombre/assets/images/zona-privada/carpeta_buscador.png";
    var secondaryImage = "/wp-content/themes/proyectohombre/assets/images/zona-privada/carpeta_breadcrumb.png";

    if (searchArray.length == 1 && $(this).attr('id') == 'tab-2-link');
    else searchArray.pop();

    breadcrumbGeneration(searchArray);

    var tabs = $('#folder-explorer li');
    console.log($('#folder-explorer li').eq(-1).find("a").attr("href"));
    //$('#document-breadcrumb').text(newBreadcrumb);


    
    if(searchArray.length > 1){
      secondTab.find('span').text(searchArray[searchArray.length-2].name);
      thirdTab.find('span').text(searchArray[searchArray.length-1].name);
      firstTab.removeClass('tab-active');
      secondTab.removeClass('tab-active');
      thirdTab.addClass('tab-active');
      //changeTab(thirdTab.find('a').attr('href'));
      changeTab(tabs.eq(-1).find('a').attr('href'))
      console.log("cargando tab 3", searchArray[searchArray.length-1].content)
      $("#document-table-wrapper-3").html(searchArray[searchArray.length-1].content);

      thirdTab.find('img').attr('src', searchArray[searchArray.length-1]['inconImage']);
      if(searchArray[searchArray.length-2]['inconImage'] == defaultImage) secondTab.find('img').attr('src', secondaryImage);
      else secondTab.find('img').attr('src', searchArray[searchArray.length-2]['inconImage']);
    }
    else if(searchArray.length == 1){
      if ($(this).attr('id') != 'tab-2-link'){
        thirdTab.find('span').text("");
        firstTab.removeClass('tab-active');
        secondTab.addClass('tab-active');
        thirdTab.removeClass('tab-active');
        changeTab(secondTab.find('a').attr('href'));
        thirdTab.hide();
        secondTab.find('span').text(searchArray[searchArray.length-1].name);
        secondTab.find('img').attr('src', searchArray[searchArray.length-1]['inconImage']);
      }
      else{
        thirdTab.removeClass('tab-active');
        thirdTab.hide();
        changeTab(secondTab.find('a').attr('href'));
      }
    }
    else{
      secondTab.find('span').text("");
      thirdTab.find('span').text("");
      changeTab(firstTab.find('a').attr('href'));
      firstTab.addClass('tab-active');
      secondTab.removeClass('tab-active');
      thirdTab.removeClass('tab-active');
      secondTab.hide();
      thirdTab.hide();
    }
  }
  /*----------------------------------
  * Document search result
  *----------------------------------*/
  if($('#document-search-form')){
    $('#document-search-form').submit(function(event){
      event.preventDefault();
      jQuery.ajax({
        type: "POST",
        url: "/wp-admin/admin-ajax.php",
        data: {
          "action":"search_docs",
          "texto": $("#search-bar").val()
        },
        success: function(response){
          searchArray = [];
          generateGrid(JSON.parse(response)["SearchDocsResult"]["NukuaDocumento"], "document-table-wrapper-2");
          firstTab.removeClass('tab-active');
          secondTab.addClass('tab-active');
          secondTab.find('span').text("Resultados de búsqueda");
          secondTab.find('img').attr('src', "/wp-content/themes/proyectohombre/assets/images/zona-privada/search-focus.svg");
          secondTab.show();

          thirdTab.find('span').text("");
          thirdTab.find('img').attr('src', "");
          thirdTab.hide();
          changeTab(secondTab.find('a').attr('href'));
          breadcrumbGeneration(searchArray);
        },
        error: function(xhr, textStatus, errorThrown ) {
          console.log("No se pudo recuperar los archivos de búsqueda");
        }
      });

    });
  }
  /*----------------------------------
  * Documents visual presentation
  *----------------------------------*/
  function generateGrid(documents, wrapperId){

    document.getElementById(wrapperId).innerHTML = "";
    var documentArray = [];
    var gridElements= [];

    if (!documents);
    else if(documents[0] == null){
      if(documents["stream_id"] != null){
        documentArray[0] = documents;
      }
    }
    else documentArray = documents;

    for(var c=0; c<documentArray.length; c++){

      var lastWriteAccessArray = documentArray[c].LastWriteAccess.split("T");
      var lastWriteAccess = lastWriteAccessArray[0]+" "+lastWriteAccessArray[1].split(".")[0];
      var stream_id = ""
      var name =""
      var abstract =""
      var keywords =""
      var ruta=""
      if (documentArray[c].stream_id) stream_id=documentArray[c].stream_id
      if (documentArray[c].name) name=documentArray[c].name
      if (documentArray[c].abstract) abstract=documentArray[c].abstract
      if (documentArray[c].keywords) keywords=documentArray[c].keywords
      if (documentArray[c].ruta) ruta=documentArray[c].ruta
      //console.log(documentArray[c]);
      gridElements.push([ 
        (documentArray[c].isdirectory == true)? name+"|"+"document-folder"+"|"+""+abstract+"|"+""+keywords+"|"+""+ruta+"|"+stream_id : name+"|"+"document-file"+"|"+abstract+"|"+""+keywords+"|"+""+ruta.replace(name,"")+"|"+stream_id ,
        documentArray[c].autor,
        lastWriteAccess,
        documentArray[c].keywords,
        (documentArray[c].isdirectory == true)? "Directorio": "Fichero",
      ]);
      console.log(gridElements[gridElements.length-1]);
    }

    console.log(gridElements);
    //console.log(wrapperId);
    
    const grid = new Grid({
      columns: [
        { 
          name: "Ficheros",
          width:"70%",
          formatter: (_, row) => html(`<div class="file-path">`+`${row.cells[0].data}`.split("|")[4]+`</div><div class="file-name">` + `${row.cells[0].data}`.split("|")[0]+`</div><div class="extra-info"><div class"extra-keywords">`+`${row.cells[0].data}`.split("|")[3]+`</div><div class="extra-abstract">`+`${row.cells[0].data}`.split("|")[2]+`</div></div>`),
          attributes: (cell) =>{
            if (cell) { 
              return {
                'name': cell.split("|")[0],
                'id': cell.split("|")[5],
                'className': cell.split("|")[1]+" gridjs-td document-grid-cell grid-document",
                'title': cell.split("|")[1]
              }
            }
          }
        },
        "Autor",
        { 
          name: "Modificado",
          formatter: (_, row) => html(`${row.cells[2].data}`.split(" ")[0]+`<br>`+`${row.cells[2].data}`.split(" ")[1])
        },
      ],
      search: true,
      sort: true,
      className: {
        table: 'document-grid',
        th: 'document-grid-header',
        td: 'document-grid-cell'
      },
      pagination: {
        enabled: true,
        limit: 20,
        summary: false
      },
      data: [],
      style: {
        "th:last-child": "display: none",
        "tr:last-child": "display: none"
      }
    }).render(document.getElementById(wrapperId));

    grid.updateConfig({
      data: gridElements,
    }).forceRender();

    $(".gridjs-search-input")[0].setAttribute("placeholder", "Ponga una palabra clave...");
  }
 function generateTableHTML(documents){
  var html = "";
  var columna = 1;
	var documentArray = [];
  if (!documents);
	else if(documents[0] == null){
		if(documents["stream_id"] != null){
			documentArray[0] = documents;
		}
	}
	else documentArray = documents;
  
  html += "<table class='document-table' cellspacing='20' border='1'>";
  for(var c=0; c<documentArray.length; c++){

    if(columna == 1) html += "<tr>";

      var documentClass = (documentArray[c]['isdirectory'] == true)? "document-folder": "document-file";
      var nombreDocumentoDepurado = documentArray[c]['iconfile'].split(".")[0]+".svg";
      var src = "";

      if(documentArray[c]['isdirectory'] == true){
        documentClass = "document-folder";
        src = (documentArray[c]['iconfile'] != "")? "/wp-content/themes/proyectohombre/assets/images/zona-privada/iconos/"+nombreDocumentoDepurado: "/wp-content/themes/proyectohombre/assets/images/zona-privada/carpeta_buscador.png";
      }
      else{
        documentClass = "document-file";
        src = (documentArray[c]['iconfile'] != "")? "/wp-content/themes/proyectohombre/assets/images/zona-privada/iconos/"+nombreDocumentoDepurado: "/wp-content/themes/proyectohombre/assets/images/zona-privada/archivo_buscador.png";
      }
      html += "<td>";
      html += "<div class='document'>";
      html += "<div id='"+documentArray[c]['stream_id']+"' class='"+documentClass+"' name='"+documentArray[c]['name']+"'>";
      html += "<img src='"+src+"'>";
      html += "<div class='file-info'>";
      html += "<div class='file-name'>"+documentArray[c]['name']+"</div>";
      if(documentArray[c]['isdirectory'] == false){
        //html += "<div class='file-extra'>asdfasdf as dfasdf asdf asdfadf<br/> asdf asdf asdf asdf adf</div>";
        html += "<div class='file-extra'>"+documentArray[c]['autor']+"<br/>"+documentArray[c]['keywords']+"</div>";
      }
      html += "</div>";
      html += "</div>";
      html += "</div>";
      html += "</td>";
    if(columna == 2){
      html += "</tr>";
      columna = 1;
    }
    else columna ++;
  }
  html += "</table>";
	return html;
 }
});